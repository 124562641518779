/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"

import Header from "./header"
import SEO from "./seo";
import fleur from "../images/fleur.svg";
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <SEO title="Svenska's Culinary" />
        <h2 style={{ fontSize: "22px", textAlign: "center" }}>Delicious Swedish treats baked locally in Charleston, SC</h2>
        <nav className="nav" style={{ marginBottom: "20px" }}>
          <div className="nav-links">
            <Link className="nav-option" to="/">
              HOME
            </Link>
            <Link className="nav-option" to="/menu">
              MENU
            </Link>
            <Link className="nav-option" to="/contact">
              MAIL
            </Link>
          </div>
          <div className="decorative-fleur">
            <img src={fleur} className="fleur-left" />
            <div className="fleur-mid"/>
            <img src={fleur} className="fleur-right"/>
          </div>
        </nav>
        <main>{children}</main>
        {/* <footer>
          © Svenska's Culinary, {new Date().getFullYear()}
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
